import { zipObject } from 'lodash-es';

export const MODEL_STATUS_MAP = {
  error: 'error',
  running: 'running',
  success: 'success',
};

const CREATING_SCHEMAS = 'creatingSchemas';

export const MODEL_STEPS = zipObject([CREATING_SCHEMAS], [CREATING_SCHEMAS]);
