export const AES_256 = 'aes_256';
export const AVRO = 'avro';
export const AWS = 'aws';
export const AWS_KMS = 'aws_kms';
export const AZURE_BLOB = 'azureBlob';
export const AZURE = 'azure';
export const BIGQUERY = 'bigquery';
// ref.: https://cloud.google.com/bigquery/docs/locations#supported_regions
export const BIGQUERY_LOCATIONS = {
  Americas: [
    'us-central1',
    'us-west4',
    'us-west2',
    'northamerica-northeast1',
    'us-east4',
    'us-west1',
    'us-west3',
    'southamerica-east1',
    'southamerica-west1',
    'us-east1',
    'northamerica-northeast2',
    'US',
  ],
  'Asia-Pacific': [
    'asia-south2',
    'asia-east2',
    'asia-southeast2',
    'australia-southeast2',
    'asia-south1',
    'asia-northeast2',
    'asia-northeast3',
    'asia-southeast1',
    'australia-southeast1',
    'asia-east1',
    'asia-northeast1',
  ],
  Europe: [
    'europe-west1',
    'europe-north1',
    'europe-west3',
    'europe-west2',
    'europe-west4',
    'europe-central2',
    'europe-west6',
    'EU',
  ],
};
export const SNOWFLAKE = 'snowflake';
export const INTERNAL_DESTINATION = 'internal_destination';
export const CASE_STYLED = {
  [AZURE]: 'Azure Blob',
  [BIGQUERY]: 'BigQuery',
  [INTERNAL_DESTINATION]: 'Crux Internal',
  [SNOWFLAKE]: 'Snowflake',
};
export const AZURE_ACTIVE_DIRECTORY = 'ACTIVE_DIRECTORY';
export const SAS_TOKEN = 'SAS_TOKEN';
export const COMMON_CONNECTION_FTP_SFTP_PARAMS = ['address', 'password', 'tls', 'username'];
export const COMMON_CONNECTION_FTP_PARAMS = ['port'];
export const COMMON_CONNECTION_GCS_PARAMS = ['bucket'];
export const COMMON_CONNECTION_S3_PARAMS = ['bucket', 'role'];
export const COMMON_CONNECTION_SFTP_PARAMS = ['port', 'sshKeyEnabled', 'privateKey', 'privateKeyPassphrase'];
export const COMMON_SOURCE_FTP_SFTP_PARAMS = ['address', 'password', 'tls', 'username'];
export const COMMON_DESTINATION_FTP_SFTP_PARAMS = [
  'address',
  'fileTypes',
  'folderPath',
  'password',
  'tls',
  'username',
  'overrideOnRevision',
  'uncompressedDelivery',
];
export const COMMON_DESTINATION_AZURE_ACTIVE_DIRECTORY_PARAMS = [
  'type',
  'tenantId',
  'clientId',
  'accountName',
  'container',
  'clientSecret',
  'folderPath',
];
export const COMMON_DESTINATION_AZURE_SAS_TOKEN_PARAMS = ['type', 'accountName', 'container', 'sasToken', 'folderPath'];

export const AZURE_AUTHENTICATION_METHODS = {
  [AZURE_ACTIVE_DIRECTORY]: AZURE_ACTIVE_DIRECTORY,
  [SAS_TOKEN]: SAS_TOKEN,
};

export const CRUX_SHORT = 'CRUX_SHORT';
export const CSV = 'csv';
export const CUSTOM = 'custom';
export const DEFAULT = 'default';
export const DESTINATION = 'destination';
export const EXTERNAL_DESTINATION = 'external_destination';
export const FTP = 'ftp';
export const GCS = 'gcs';
export const HTTP = 'http';
export const PARQUET = 'parquet';
export const JSON = 'json';
export const RAW = 'raw';
export const SFTP = 'sftp';
export const SOURCE = 'source';
export const S3 = 's3';

// Compression types
export const DEFLATE = { label: 'DEFLATE', value: 'DEFLATE' };
export const SNAPPY = { label: 'SNAPPY', value: 'SNAPPY' };
export const GZIP = { label: 'GZIP', value: 'GZIP' };
export const UNCOMPRESSED_DELIVERY = { label: 'Uncompressed delivery', value: 'NONE' };
export const ZSTD = { label: 'ZSTD', value: 'ZSTD' };

export const EMPTY_BASE_CONNECTION = {
  config: {},
  method: '',
  name: '',
};
export const DEFAULT_NEW_CONNECTION_TYPE = SOURCE;
export const STORAGE_PATH_TYPES = [CRUX_SHORT];
export const DEFAULT_LIMITED_DESTINATION_FILE_TYPES = [AVRO, CSV];
export const EMPTY_DESTINATION_CONNECTION_CONFIG = {
  fileTypes: [],
  storagePath: STORAGE_PATH_TYPES[0],
};
export const EMPTY_STRING_REPLACEMENT = '---';
export const FILE_TYPE_MAPPING = {
  [AVRO]: 'Avro',
  [CSV]: 'CSV',
  [JSON]: 'JSON',
  [PARQUET]: 'Parquet',
  [RAW]: 'Raw',
};
export const FILE_TYPE_COMPRESSIONS = {
  Avro: [DEFLATE, SNAPPY],
  CSV: [GZIP, UNCOMPRESSED_DELIVERY],
  JSON: [GZIP, UNCOMPRESSED_DELIVERY],
  Parquet: [GZIP, SNAPPY, ZSTD],
  Raw: [],
};
export const S3_DESTINATION_ENCRYPTION_TYPES = [AES_256, AWS_KMS];
export const SNOWFLAKE_VENDOR_OPTIONS = [AWS];
export const STORAGE_PATH_TYPE_MAPPING = {
  [CRUX_SHORT]: 'Standard short',
};
export const CONNECTION_IMAGES = {
  azure: 'azure.svg',
  bigquery: 'bigquery.svg',
  ftp: 'ftp.svg',
  gcs: 'gcs.svg',
  s3: 's3.svg',
  sftp: 'sftp.svg',
  snowflake: 'snowflake.svg',
};
export const CONNECTION_MAP = {
  aws: 'AWS S3',
  aws_kms: 'AWS KMS',
  azure: 'Azure',
  azureBlob: 'Azure Blob',
  bigquery: 'BigQuery',
  ftp: 'FTP',
  gcs: 'GCS',
  http: 'HTTP',
  https: 'HTTPS',
  s3: 'S3',
  sftp: 'SFTP',
  snowflake: 'Snowflake',
};
export const PARAM_LINKED_PRODUCTS = 'linkedProducts';
export const PARAM_LINKED_PRODUCTS_COUNT = 'linkedProductsCount';
export const SECRET_VALUE_DISPLAY = '**********';
export const SECRET_VALUE_READ_ONLY_DISPLAY = '••••••••••';

// connections config
export const FTP_SOURCE_CONFIG = {
  port: 21,
};
export const FTP_DESTINATION_CONFIG = {
  ...EMPTY_DESTINATION_CONNECTION_CONFIG,
  port: 21,
};
export const SFTP_SOURCE_CONFIG = {
  port: 22,
};
export const SFTP_DESTINATION_CONFIG = {
  ...EMPTY_DESTINATION_CONNECTION_CONFIG,
  port: 22,
};
export const S3_SOURCE_CONFIG = {};
export const S3_DESTINATION_CONFIG = {
  ...EMPTY_DESTINATION_CONNECTION_CONFIG,
};
export const GCS_SOURCE_CONFIG = {};
export const GCS_DESTINATION_CONFIG = {
  ...EMPTY_DESTINATION_CONNECTION_CONFIG,
};
export const BIGQUERY_DESTINATION_CONFIG = {
  ...EMPTY_DESTINATION_CONNECTION_CONFIG,
  fileTypes: DEFAULT_LIMITED_DESTINATION_FILE_TYPES,
};
export const SNOWFLAKE_DESTINATION_CONFIG = {
  ...EMPTY_DESTINATION_CONNECTION_CONFIG,
  fileTypes: DEFAULT_LIMITED_DESTINATION_FILE_TYPES,
};
export const AZURE_DESTINATION_CONFIG = {
  ...EMPTY_DESTINATION_CONNECTION_CONFIG,
};
